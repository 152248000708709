<template>
  <table class="table table-bordered covering">
    <tbody>
    <tr aria-rowindex="1" v-for="(row, index) in data[code]" :key="index">
      <td>
        {{ row[0] }}
      </td>
      <td>
        {{ row[1] }}
      </td>
    </tr>

    </tbody>
  </table>
</template>

<script>
export default {
  name: "CoverageTable",
  computed: {
    code(){
      return this.$product + '_' + this.$i18n.locale;
    }
  },
  data: function (){
    return {
      data: {
        cp_sk: [

        ],
        cp_cz: [

        ],
        gp_sk: [
          ["Hole in One", "1 000  €"],
          ["golfové vybavenie – poškodenie, krádež","3 000  €"],
          ["náklady na zapožičanie / kúpu náhradného golfového vybavenia","600  €"],
          ["zodpovednosť za škody spôsobené pri hre","40 000  €"],
          ["poistenie členských príspevkov", "1 200  €"]
        ],
        gp_cz: [
          ["Hole in One", "25 000 Kč"],
          ["golfové vybavení – poškození, krádež", "75 000 Kč"],
          ["výlohy na zapůjčení / koupi náhradního golfového vybavení", "15 000 Kč"],
          ['odpovědnost za škody způsobené při hře', '1 000 000 Kč'],
          ['pojištěni členských příspěvků', '30 000 Kč']

        ]
      }
    }
  }
}
</script>

<style scoped>

</style>