<template>
  <header class="bg-light">
    <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <div class="">
        <h1><router-link to="/">{{ $t('website') }}</router-link></h1>
      </div>
      <div class="text-end p-3">
        <a :href="'mailto:' + $t('contact.email')">{{ $t('contact.email') }}</a><br>
        <a :href="'tel' + $t('contact.tel')">{{ $t('contact.tel') }}</a>
      </div>
    </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderPart",
  props: {
    product: String
  }

}
</script>

<style scoped>
  header{
    border-bottom: 1px solid var(--main-color);
  }
  h1{
    padding: 20px 0;
  }

  h1 a{
    text-decoration: none;
    color: #333;
    color: var(--main-color);
  }

  a{
    text-decoration: none;
    color: var(--main-color);
  }
</style>