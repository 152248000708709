import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
//import Vue from 'vue';
import i18n from '../i18n'
const {
  global: { t },
} = i18n;

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {title: ''},
    component: HomeView
  },
  {
    path: '/files',
    name: 'files',
    meta: {title: t('files.info')},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FilesView.vue')
  },
  // {
  //   path: '/order',
  //   name: 'order',
  //   meta: {title: ''},
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/FormView.vue')
  // },
  // {
  //   path: '/order/:orderUUID/payment',
  //   name: 'orderPayment',
  //   meta: {title: t('payment.title')},
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/PaymentView.vue')
  // },
  // {
  //   path: '/order/:orderUUID/payment/:status',
  //   name: 'orderPaymentWithParam',
  //   meta: {title: t('payment.title')},
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/PaymentView.vue')
  //}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
const DEFAULT_TITLE = t('website');
router.afterEach((to) => {
  console.log('after router');
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  // Vue.nextTick(() => {
    //console.log('next tick');
    document.title = to.meta.title + (to.meta.title? ' - ' : '')+ DEFAULT_TITLE;
  // });
});

export default router
