<template>
  <div class="page-wrap">
  <HeaderPart></HeaderPart>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <div class="main-content">
  <router-view/>
  </div>

  <FooterPart></FooterPart>
  </div>
</template>

<!--<style lang="scss">-->
<!--// Import custom SASS variable overrides, or alternatively-->
<!--// define your variable overrides here instead-->
<!--$blue:    #0d6efd;-->
<!--$indigo:  #6610f2;-->
<!--$purple:  #6f42c1;-->
<!--$pink:    #d63384;-->
<!--$red:     #dc3545;-->
<!--$orange:  #fd7e14;-->
<!--$yellow:  #ffc107;-->
<!--$green:   #198754;-->
<!--$teal:    #20c997;-->
<!--$cyan:    #0dcaf0;-->

<!--// Import Bootstrap and BootstrapVue source SCSS files-->
<!--@import '~bootstrap/scss/bootstrap.scss';-->
<!--</style>-->

<style>
.page-wrap{ min-height:100vh; margin:0; }

header{ min-height:50px; background:lightcyan; }
footer{ min-height:50px; background:PapayaWhip; }

/* Trick */
.page-wrap{
  display:flex;
  flex-direction:column;
}

footer{
  margin-top:auto;
}
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}


:root{
  --main-color: #00b050;
  --main-color-light: #eafff4;
}
html a{
  color: var(--main-color)
}
nav a.router-link-exact-active {
  color: #42b983;
}

h1, h2, h3, h4{
  color: var(--main-color)
}

.hero{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: left bottom 20%;
  min-height: 350px;
  padding-top: 1px;

}
.btn-primary{

  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

</style>

<script>
import HeaderPart from "@/components/parts/HeaderPart";
import FooterPart from "@/components/parts/FooterPart";
export default {
  components: {FooterPart, HeaderPart}
}
</script>