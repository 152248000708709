<template>
  <div class="hero has-content" style="background-image: url('/img/gp/golf_hero_1920.jpg')">
    <div class="container">
      <h2 class="mt-4">{{$t("home.coverage_title")}}</h2>
    </div>
  </div>
<!--  <div class="hero top" style="background-image: url('/img/gp/golf_hero_1920.jpg')">-->

<!--  </div>-->
<!--  <div class="container">-->
<!--    <h2 class="mt-5 mb-5">{{$t("home.coverage_title")}}</h2>-->
<!--  </div>-->
  <div class="container mt-5">

    <div class="row d-flex justify-content-between align-items-stretch">
      <div class="col-md-6">
        <CoverageTable></CoverageTable>
      </div>
      <div class="col-md-6 text-center d-flex flex-md-row align-items-center  justify-content-center">
<!--        <div class="price-wrap d-flex flex-column justify-content-end">-->
<!--          <div class="price">-->
<!--            <div class="price-title">-->
<!--              {{$t('home.price_title')}}-->
<!--            </div>-->
<!--            {{$t('home.price')}}-->
<!--          </div>-->
<!--        </div>-->
        <div class="text-start" v-html="$t('off_text')">

        </div>
<!--        <router-link to="/order" class="btn btn-primary shadow-lg btn-lg">{{$t('home.cta')}}</router-link>-->

      </div>
    </div>
  </div>
<!--  <div class="hero mt-5" style="background-image: url('/img/gp/hero_2.png')">-->
<!--  </div>-->
  <div class="container">
    <h3 class="mt-5">{{$t("home.faq_title")}}</h3>

    <div class="mb-4 mt-4">
      <FAQPart />
    </div>
  </div>


<!--  <div class="home">-->
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--  </div>-->

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
//import HeaderPart from "@/components/parts/HeaderPart";
import FAQPart from "@/components/accordion/FAQPart";
import CoverageTable from "@/components/parts/CoverageTable";

export default {
  name: 'HomeView',
  data: function (){
    return {

    }
  },
  components: {
    CoverageTable,
    FAQPart
  }
}
</script>
<style>
img{
  max-width: 100%;

}
strong{
  color: var(--main-color);
}


.hero.has-content:after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  opacity: 1;
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.0) 70%);
}
.hero .container{
  position: relative;
  z-index: 2;
}
.hero h2{
  font-size: 2.5rem;
  margin-top: 320px;
  padding-bottom: 15px;
  color: white;
}



.covering{
  font-size: 0.9em;

}
.covering td{
  padding: 5px;
}
.table td:nth-child(2){
  text-align: right;
}

.price-wrap{
  margin-right: 100px;
  /*flex: 1;*/
}
.price{
  text-align: center;
  font-size: 3em;
  color: var(--main-color);
  font-weight: bold;

}
.price-title{
  font-weight: normal;
  font-size: 0.5em;
  margin-bottom: -10px;
}
</style>
